var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-tooltip',_vm._b({attrs:{"disabled":!_vm.tooltip},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',[(_vm.options.labels || _vm.options.labelItems)?[_vm._l((_vm.labels),function(labelItem,index){return [_c('v-chip',_vm._g(_vm._b({key:index,staticClass:"mr-1 mb-1 mt-1",style:({
              visibility: (
                typeof labelItem === 'object' ? labelItem.value : labelItem
              )
                ? 'visible'
                : 'hidden',
            }),attrs:{"label":""},domProps:{"innerHTML":_vm._s(
              typeof labelItem === 'object' ? labelItem.value : labelItem
            )}},'v-chip',Object.assign({}, Object.assign({}, {color: 'primary',
                dark: true,
                small: true},
                _vm.options.content.template.label.attrs,
                ((labelItem && labelItem.attrs) || {})),
              _vm.attrs),false),on)),(
              _vm.options.labelsSeparatedByNewLine && index < _vm.labels.length - 1
            )?_c('br',{key:(index + "_new_line")}):_vm._e()]})]:(_vm.options.label)?[(_vm.label)?_c('v-chip',_vm._g(_vm._b({attrs:{"label":""},domProps:{"innerHTML":_vm._s(_vm.label)}},'v-chip',Object.assign({}, Object.assign({}, {color: 'primary',
              dark: true,
              small: true},
              _vm.options.content.template.label.attrs),
            _vm.attrs),false),on)):_vm._e()]:(_vm.options.icon)?[_c('v-icon',_vm._g(_vm._b({},'v-icon',_vm.attrs,false),on),[_vm._v(_vm._s(_vm.icon))])]:(_vm.options.to)?[_c('router-link',_vm._g(_vm._b({attrs:{"to":_vm.to},domProps:{"innerHTML":_vm._s(_vm.finalValue)}},'router-link',_vm.attrs,false),on))]:(_vm.options.href)?[_c('a',_vm._g(_vm._b({attrs:{"href":_vm.href},domProps:{"innerHTML":_vm._s(_vm.finalValue)}},'a',_vm.attrs,false),on))]:(_vm.options.boolean)?[_c('v-icon',_vm._g(_vm._b({},'v-icon',Object.assign({}, {color: _vm.finalValue ? 'green' : 'red'}, _vm.attrs),false),on),[(_vm.finalValue)?[_vm._v(" mdi-check ")]:[_vm._v(" mdi-close ")]],2)]:(_vm.options.json)?[(_vm.finalValue)?_c('json-viewer',_vm._g(_vm._b({attrs:{"value":_vm.finalValue,"expanded":false,"expand-depth":0}},'json-viewer',_vm.attrs,false),on)):_vm._e()]:(_vm.options.textMaxWidth)?[_c('span',_vm._g(_vm._b({staticClass:"d-inline-block text-truncate",style:(("max-width: " + (_vm.options.textMaxWidth))),domProps:{"innerHTML":_vm._s(_vm.finalValue)}},'span',_vm.attrs,false),on))]:(_vm.options.type)?[_c(_vm.options.type,_vm._g(_vm._b({tag:"component"},'component',_vm.attrs,false),on))]:[_c('span',_vm._g(_vm._b({domProps:{"innerHTML":_vm._s(_vm.finalValue)}},'span',_vm.attrs,false),on))]],2)]}}])},'v-tooltip',_vm.tooltipAttrs,false),[_c('span',{domProps:{"innerHTML":_vm._s(_vm.tooltip)}})])}
var staticRenderFns = []

export { render, staticRenderFns }